import React from 'react'
import { Companie, PromoCodeGlobal } from '__generated__/graphql'
import { gql } from '__generated__'
import { useQuery } from '@apollo/client'
import Error from 'components/nav/error/Error'
import Loading from 'components/nav/error/Loading'
import NotFound from 'components/nav/error/NotFound'
import PaymentMethodFormatSource from 'components/paymentSource/single/PaymentMethodFormatSource'
import AddPaymentSourceLogicStripeMaven from 'components/paymentSource/list/AddPaymentSourceLogicStripeMaven'
import CreateInvoiceMavenPayment from 'components/invoice/list/maven/CreateInvoiceMavenPayment'
import { useMavenKYCContext } from '../MavenKYCContext'
import GetStatusRedeemPromoCodeGlobal from 'components/promoCode/GetStatusRedeemPromoCodeGlobal'
import utils from 'components/utils'

const SOURCES_QUERY = gql(/* GraphQL */ `
  query sourcesConnection_SellerPaymentSourceForm(
    $where: SourceWhereInput
    $orderBy: SourceOrderByInput
    $first: Int
    $skip: Int
  ) {
    sourcesConnection(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      edges {
        node {
          id
          typeSource
          isDefaultSource
          last4
          status
          country
          nickname
          statusVerificationSource
          brand
          bank_name
          object
          funding
          exp_month
          exp_year
          name
          plaidData {
            id
            resetLogin
            publicTokenFresh
          }
          companie {
            id
            incomingPaymentForeignFeeCard
            allowPrepaidCardFunding
          }
        }
      }
    }
  }
`)

type Props = {
  onUpdate: () => void
  companie: Pick<
    Companie,
    | 'id'
    | 'typeCompanie'
    | 'addStripeBank'
    | 'incomingPaymentFeeACHCard'
    | 'hideDebitCard'
    | 'incomingPaymentFeeDebitCard'
    | 'hideDebitCredit'
    | 'incomingPaymentFeeCreditCard'
  >
}

const MavenPaymentFormChild = (props: Props) => {
  const fullPrice = 250
  const mavenKYCContext = useMavenKYCContext()
  const [promoCodeGlobal, setPromoCodeGlobal] = React.useState<Pick<
    PromoCodeGlobal,
    'id' | 'code' | 'description' | 'discount'
  > | null>(null)
  console.log('context', mavenKYCContext)
  const { loading, error, data } = useQuery(SOURCES_QUERY, {
    variables: {
      where: {
        companie: { id: props.companie.id },
        isDefaultSource: true,
      },
    },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.sourcesConnection) return <NotFound />

  const defaultSource = data.sourcesConnection.edges.length > 0 ? data.sourcesConnection.edges[0].node : null

  const disabled = mavenKYCContext.getStepStatus('payment')
  return (
    <>
      <div style={{ margin: '30px auto' }}>
        {/* <p
          style={{
            maxWidth: '560px',
            fontFamily: 'Roobert',
            // textAlign: 'center',
            fontSize: '16px',
            lineHeight: '22px',
            color: themeNN.lightGrey.color,
          }}
        >
          A one time fee is required to insert your profile in the NachoNacho Services Marketplace
        </p> */}

        <p>Listing Fee: {utils.priceFormatedUSDNoDecimals(fullPrice)}</p>
        <div style={{ marginTop: 60, marginBottom: 20 }}>
          {defaultSource ? (
            <>
              <PaymentMethodFormatSource showIcon={true} source={defaultSource} />
              <div style={{ marginTop: 30, marginBottom: 30 }}>
                <GetStatusRedeemPromoCodeGlobal
                  companieId={props.companie.id}
                  fullPrice={fullPrice}
                  setPromoCodeGlobal={setPromoCodeGlobal}
                  promoCodeGlobal={promoCodeGlobal}
                />
              </div>
              <div>
                <CreateInvoiceMavenPayment
                  companieId={props.companie.id}
                  onCreate={props.onUpdate}
                  disabled={disabled}
                  promoCodeGlobalId={promoCodeGlobal?.id}
                />
              </div>
            </>
          ) : (
            <div>
              <AddPaymentSourceLogicStripeMaven companie={props.companie} />
            </div>
          )}
        </div>
        <p style={{ marginTop: 40 }}>
          Your submission will only be considered complete once the Listing Fee has been paid. If your submission is rejected, the
          Listing Fee will be refunded to you.
        </p>
      </div>
    </>
  )
}
export default MavenPaymentFormChild
