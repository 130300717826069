import React from 'react'
import { Button, MenuItem } from '@mui/material/'
import UpdateUser from '../../action/UpdateUser'
import TextFieldNN from 'components/ui/TextFieldNN'
import { User, WhereDidYouHearAboutUsSource } from '__generated__/graphql'
import SelectFieldNN from 'components/ui/SelectFieldNN'
import utils from 'components/utils'

type Props = {
  showCancelButton: boolean
  updateTextButton: string
  cancelTextButton: string
  user: Pick<User, 'id' | 'whereDidyouHearAboutUs' | 'whereDidYouHearAboutUsSource'>
  onUpdate: () => void
  onCancel: () => void
}

const UserWhereHearAboutUsForm = (props: Props) => {
  const [user, setUser] = React.useState(props.user)
  const getLabelWhereDidYouHearAboutUs = () => {
    if (user.whereDidYouHearAboutUsSource === WhereDidYouHearAboutUsSource.Partner) return 'Which partner?'
    if (user.whereDidYouHearAboutUsSource === WhereDidYouHearAboutUsSource.Advertising) return 'Where did you see the ad?'
    if (user.whereDidYouHearAboutUsSource === WhereDidYouHearAboutUsSource.Email) return 'What was the topic of the email?'
    if (user.whereDidYouHearAboutUsSource === WhereDidYouHearAboutUsSource.Other) return 'Please share more details:'
    if (user.whereDidYouHearAboutUsSource === WhereDidYouHearAboutUsSource.Site)
      return 'Which search term or website referred you?'
    if (user.whereDidYouHearAboutUsSource === WhereDidYouHearAboutUsSource.SocialMedia) return 'Which social media site?'
    return ''
  }
  return (
    <div>
      <br />

      <SelectFieldNN
        label="Pick a source"
        id="id-whereDidYouHearAboutUsSource"
        value={user.whereDidYouHearAboutUsSource}
        onChange={(e) => setUser({ ...user, whereDidYouHearAboutUsSource: e.target.value as WhereDidYouHearAboutUsSource })}
      >
        <MenuItem value={WhereDidYouHearAboutUsSource.Partner}>
          {utils.getFriendlyWhereDidYouHearAboutUsSource(WhereDidYouHearAboutUsSource.Partner)}
        </MenuItem>
        <MenuItem value={WhereDidYouHearAboutUsSource.Site}>
          {utils.getFriendlyWhereDidYouHearAboutUsSource(WhereDidYouHearAboutUsSource.Site)}
        </MenuItem>
        <MenuItem value={WhereDidYouHearAboutUsSource.SocialMedia}>
          {utils.getFriendlyWhereDidYouHearAboutUsSource(WhereDidYouHearAboutUsSource.SocialMedia)}
        </MenuItem>
        <MenuItem value={WhereDidYouHearAboutUsSource.Email}>
          {utils.getFriendlyWhereDidYouHearAboutUsSource(WhereDidYouHearAboutUsSource.Email)}
        </MenuItem>
        <MenuItem value={WhereDidYouHearAboutUsSource.Advertising}>
          {utils.getFriendlyWhereDidYouHearAboutUsSource(WhereDidYouHearAboutUsSource.Advertising)}
        </MenuItem>
        <MenuItem value={WhereDidYouHearAboutUsSource.Other}>
          {utils.getFriendlyWhereDidYouHearAboutUsSource(WhereDidYouHearAboutUsSource.Other)}
        </MenuItem>
      </SelectFieldNN>
      {user.whereDidYouHearAboutUsSource && (
        <TextFieldNN
          label={getLabelWhereDidYouHearAboutUs()}
          id="id-whereDidyouHearAboutUs"
          onChange={(e) => {
            setUser({
              ...user,
              whereDidyouHearAboutUs: e.target.value,
            })
          }}
          type="text"
          value={user.whereDidyouHearAboutUs}
        />
      )}
      <div>
        {props.showCancelButton && <Button onClick={() => props.onCancel()}>{props.cancelTextButton}</Button>}{' '}
        <UpdateUser disabled={false} user={user} updateTextButton={props.updateTextButton} onUpdate={() => props.onUpdate()} />
      </div>
    </div>
  )
}

export default UserWhereHearAboutUsForm
