import React from 'react'
import { Companie } from '__generated__/graphql'
import { Button } from '@mui/material'
import MavenPaymentFormChild from './MavenPaymentFormChild'

type Props = {
  onUpdate: () => void
  companie: Pick<
    Companie,
    | 'id'
    | 'typeCompanie'
    | 'addStripeBank'
    | 'incomingPaymentFeeACHCard'
    | 'hideDebitCard'
    | 'incomingPaymentFeeDebitCard'
    | 'hideDebitCredit'
    | 'incomingPaymentFeeCreditCard'
  >
}

const MavenPaymentForm = (props: Props) => {
  const [step, setStep] = React.useState(0)

  return (
    <>
      <div className="titleStepKYC">NachoNacho Fees</div>

      <div style={{ maxWidth: '500px', margin: '40px auto 0', fontSize: '16px' }}>
        {step === 0 && (
          <div>
            <div>
              1) <b>Referral Fees</b>: Charged only if/when a payment is made to you by a customer:
              <ul style={{ lineHeight: '8px', marginTop: 6, marginBottom: 20 }}>
                <li>15% on the first $10k</li>
                <li>10% on the next $10k</li>
                <li>5% thereafter</li>
              </ul>
              2) (a) <b>Launch Fee</b>: Permanent listing in the Services Marketplace combined with an extensive marketing boost
              to the entire NachoNacho network. One time fee. Two packages available: $2k or $3k.{' '}
              <a className="link" href="https://nachonacho.com/contact" target="_blank" rel="noreferrer">
                Contact us
              </a>{' '}
              to learn more.
            </div>
            <div style={{ marginTop: 20, marginBottom: 20 }}>OR</div>
            2) (b) <b>Listing Fee</b>: $250 one-time fee. Permanent listing in the Services Marketplace.
            <div style={{ marginTop: 30 }}>
              <Button color="primary" sx={{ width: '100%' }} variant="contained" size="medium" onClick={() => setStep(1)}>
                Next
              </Button>
            </div>
          </div>
        )}
        {step === 1 && <MavenPaymentFormChild onUpdate={props.onUpdate} companie={props.companie} />}
      </div>
    </>
  )
}
export default MavenPaymentForm
