import React from 'react'
import { Grid, Paper } from '@mui/material'
import MenuLeftDesktop from '../drawer/topLeft/MenuLeftDesktop'
import ContactUsOnboardingLink from '../../../onboarding/ContactUsOnboardingLink'
import LogoCompose from '../../../onboarding/LogoCompose'
import LogOutCross from 'components/nav/LogoutCross'
import { useAppContextWithCompany } from 'components/AppContext'
import UserWhereHearAboutUs from '../profile/sectionDetails/UserWhereHearAboutUs'

// const COMPANIE_QUERY_TEAM = gql(/* GraphQL */ `
//   query companie_CompanieVerificationPageSplash($where: CompanieWhereUniqueInput!) {
//     companie(where: $where) {
//       id
//       name
//       statusVerification
//       typeCompanie
//       verificationDateSubmission
//     }
//   }
// `)

const UserWhereDidYouHearAboutUsOffSite = () => {
  const context = useAppContextWithCompany()

  // const { me: user, userRoleCompanie } = context

  // const { loading, error, data } = useQuery(COMPANIE_QUERY_TEAM, {
  //   variables: {
  //     where: {
  //       id: userRoleCompanie.companie.id,
  //     },
  //   },
  // })

  // if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  // if (loading) return <Loading />
  // if (!data?.companie) return <NotFound />
  // const companie = data.companie

  return (
    <div className="responsiveMargin2">
      <div className="paperOut">
        <Paper variant="outlined" className="paperIn">
          <>
            <Grid container>
              <Grid item xs={6}>
                <MenuLeftDesktop />
              </Grid>
              <Grid item xs={6} className="tar">
                <LogOutCross />
              </Grid>
            </Grid>

            <LogoCompose />
            <div className="tal responsiveMargin2">
              <UserWhereHearAboutUs userId={context.me.id} defaultIsEdit={true} />
            </div>

            <Grid container>
              <Grid item xs={12} sm={12} className="tar">
                <ContactUsOnboardingLink />
              </Grid>
            </Grid>
          </>
        </Paper>
      </div>
    </div>
  )
}

export default UserWhereDidYouHearAboutUsOffSite
